<script setup lang="ts">
  import { onKeyStroke, useWindowSize } from '@vueuse/core'
  import { WINDOW_BREAKPOINT_MOBILE } from '~~/types/window'

  const MODAL_TRANSITION_DELAY = 200

  const props = defineProps<{
    opened: boolean
    centered?: boolean
    noOverlay?: boolean
    notDismissible?: boolean
    fullHeight?: boolean
    fullWidth?: boolean
  }>()

  const emit = defineEmits(['close'])

  const { width } = useWindowSize()
  const animating = ref<boolean>(false)

  const open = (): void => {
    animating.value = true
    setTimeout(() => (animating.value = false), 0)
    document.body.style.overflow = 'hidden'
  }
  const close = (): void => {
    if (props.notDismissible) {
      return
    }

    animating.value = true

    setTimeout(() => {
      animating.value = false
      emit('close')
      document.body.style.overflow = 'initial'
    }, MODAL_TRANSITION_DELAY)
  }

  watch(
    () => props.opened,
    (value) => (value ? open() : close()),
    { immediate: true },
  )

  onKeyStroke(['escape', 'Escape'], (e: KeyboardEvent) => {
    e.preventDefault()
    close()
  })

  onBeforeUnmount(() => {
    document.body.style.overflow = 'initial'
  })
</script>

<template>
  <div
    v-if="opened || animating"
    class="fixed inset-0 flex"
  >
    <Transition
      name="modal-overlay"
      mode="out-in"
    >
      <div
        v-if="!animating && !noOverlay"
        class="absolute inset-0"
        @click="close()"
      >
        <slot name="overlay">
          <OverlayBase />
        </slot>
      </div>
    </Transition>

    <Transition
      :name="width < WINDOW_BREAKPOINT_MOBILE ? 'modal-slide-up' : 'modal-fade'"
      mode="out-in"
    >
      <div
        v-if="!animating && opened"
        class="relative flex max-h-screen"
        :class="{
          'mx-auto mb-0 mt-auto md:mb-auto': centered !== false,
          'h-full sm:h-auto': fullHeight,
          'w-full': fullWidth,
          'w-full sm:w-auto': !fullWidth,
        }"
      >
        <slot />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
  .modal-overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-200 ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      @apply opacity-0;
    }

    &-enter-to,
    &-leave-from {
      @apply opacity-100;
    }
  }

  .modal-fade {
    &-enter-active,
    &-leave-active {
      @apply transition duration-300 ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      @apply opacity-0;
    }

    &-enter-to,
    &-leave-from {
      @apply opacity-100;
    }
  }

  .modal-slide-up {
    &-enter-active,
    &-leave-active {
      @apply transition duration-300 ease-in-out;
    }

    &-enter-from,
    &-leave-to {
      @apply translate-y-1/2 opacity-0;
    }

    &-enter-to,
    &-leave-from {
      @apply translate-y-0 opacity-100;
    }
  }
</style>
